import React, { useCallback } from "react";
import {
    DA_DynamicOverlayWrapper,
    useDynamicOverlay,
} from "@danishagro/shared/src/hooks/dynamicOverlay/useDynamicOverlay.hook";
import { DA_BasicLink } from "@danishagro/shared/src/components/atoms/BasicLink/BasicLink.component";
import { DA_Image } from "@danishagro/shared/src/components/atoms/Image/Image.component";
import {
    DA_Button,
    DA_ButtonColor,
} from "@danishagro/shared/src/components/atoms/Button/Button.component";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { DA_QuantityPicker } from "@molecules/QuantityPicker/QuantityPicker.component";
import { useAppData } from "@danishagro/shared/src/contexts/appData.context";
import { useCart } from "@danishagro/shared/src/contexts/cart/cart.context";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { useUpdateCartItem } from "@hooks/useUpdateCartItem.hook";
import { B2bImageSrc } from "@danishagro/shared/src/helpers/imageSrc.helper";
import { ImageConfig } from "@danishagro/shared/src/content/imageConfigs/imageConfig.enum";
import { formatNumber } from "@danishagro/shared/src/helpers/formatNumber.helper";
import { DA_DriverMessageMobileButton } from "../ScreenSizes/CartItemMobile/components/DriverMessageMobileButton/DriverMessageMobileButton.component";
import { DA_CartItemDrawerProps } from "./CartItemDrawer.interface";
import S from "./CartItemDrawer.module.scss";

export const DA_CartItemDrawer = ({ ...props }: DA_CartItemDrawerProps) => {
    const { getDictionaryString } = useTranslations();
    const { customerNumber, showPrices, isFarmInTimeEnabled, currentCulture } = useAppData();
    const { orderSecret, priceStatus, disableCropProtectionDelivery } = useCart();

    const { closeOverlay } = useDynamicOverlay();

    const updateDriverMessage = (newDriverMessage: string) => {
        // Update the currentDriverMessage state with the new value
        props.setCurrentDriverMessage(newDriverMessage);
    };

    const {
        isWithoutFeeAndChargesJSX,
        isFeeAndChargesJSX,
        withoutFeeAndChargesPriceValue,
        feeAndChargesPriceValue,
    } = useUpdateCartItem(props);

    const formatPrice = useCallback(
        (price: number) => formatNumber(price, currentCulture, { decimals: 2 }),
        [currentCulture]
    );

    return (
        <DA_DynamicOverlayWrapper
            content={
                <div className={S.drawerProductItem}>
                    <div className={S.mainInfo}>
                        {/** Image */}
                        <div className={S.imageWrapper}>
                            <DA_BasicLink href={props.url} className={S.imageLink}>
                                <DA_Image
                                    src={B2bImageSrc(props.imageUrl, ImageConfig.PlpImage)}
                                    alt={props.sizedImageMobile.alt}
                                    width={props.sizedImageMobile.width}
                                    height={props.sizedImageMobile.height}
                                    className={S.image}
                                />
                            </DA_BasicLink>
                        </div>

                        {/** Name */}
                        <div className={S.numberAndName}>
                            <div className={S.number}>
                                {props.productNumber}

                                {props.data.allowCropProtectionDelivery &&
                                    !props.hideDeliveryOptions && (
                                        <>
                                            {disableCropProtectionDelivery && (
                                                <span className={cn(S.tag, S.tagItemWeight)}>
                                                    {`1 ${props.data.unit}: ${
                                                        props.data.itemGrossWeight % 1 === 0
                                                            ? formatNumber(
                                                                  props.data.itemGrossWeight,
                                                                  currentCulture,
                                                                  {
                                                                      decimals: 0,
                                                                  }
                                                              )
                                                            : formatNumber(
                                                                  props.data.itemGrossWeight,
                                                                  currentCulture,
                                                                  {
                                                                      decimals: 2,
                                                                  }
                                                              )
                                                    } kg`}
                                                </span>
                                            )}
                                        </>
                                    )}
                            </div>

                            <div className={S.name}>
                                <DA_BasicLink href={props.url} className={S.nameLink}>
                                    {props.productName}
                                </DA_BasicLink>
                            </div>
                        </div>
                    </div>
                    <div className={S.mobileActionWrapper}>
                        {showPrices && (
                            <div className={S.mobileActionWrapperItem}>
                                {/** Delivery Date */}
                                <div className={S.deliveryDate}>
                                    <span className={S.dateLabel}>
                                        {getDictionaryString("latest delivery date", {
                                            uppercaseFirst: true,
                                        })}
                                        :{" "}
                                    </span>
                                    <span className={S.dateShipping}>
                                        {props.priceData.shippingDate}
                                    </span>
                                </div>
                            </div>
                        )}

                        {/* Amount picker */}
                        <div className={S.mobileActionWrapperItem}>
                            <div className={S.actionAmountWrapper}>
                                <div className={S.actionAmountWrapperTitle}>
                                    {getDictionaryString("amount")}

                                    {props.data.allowCropProtectionDelivery &&
                                        !props.hideDeliveryOptions && (
                                            <>
                                                {disableCropProtectionDelivery && (
                                                    <span className={cn(S.tag, S.tagTotalWeight)}>
                                                        {`${getDictionaryString("TotalWieght")}: ${
                                                            props.data.lineGrossWeight % 1 === 0
                                                                ? formatNumber(
                                                                      props.data.lineGrossWeight,
                                                                      currentCulture,
                                                                      { decimals: 0 }
                                                                  )
                                                                : formatNumber(
                                                                      props.data.lineGrossWeight,
                                                                      currentCulture,
                                                                      { decimals: 2 }
                                                                  )
                                                        } kg`}
                                                    </span>
                                                )}
                                            </>
                                        )}
                                </div>

                                <div className={S.actionAmountWrapperInput}>
                                    {!props.readOnly ? (
                                        <DA_QuantityPicker
                                            onChange={props.updateQuantity}
                                            value={props.quantity}
                                            disabled={props.dimmed}
                                            higherMax={!isFarmInTimeEnabled}
                                        />
                                    ) : (
                                        <div>
                                            {props.quantity} {props.unit}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        {/** Price */}
                        {showPrices && (
                            <div className={S.mobileActionWrapperItem}>
                                <div className={S.total}>
                                    <span className={S.totalLabel}>
                                        {getDictionaryString("price")}
                                    </span>

                                    <span className={S.totalPrice}>
                                        {/* TODO: use this logic */}
                                        {props.priceData.price}

                                        {props.priceData.charges.length > 0 && (
                                            <div className={S.charges}>
                                                {props.priceData.charges.map((fee) => (
                                                    <div
                                                        className={S.chargesInfoItem}
                                                        key={fee.code}
                                                    >
                                                        {fee.title}: {fee.price}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </span>
                                </div>

                                <div className={cn(S.charges, S.extraFees)}>
                                    {/* Agreed Price */}
                                    <span className={S.extraFeesItem}>
                                        {isWithoutFeeAndChargesJSX
                                            ? withoutFeeAndChargesPriceValue.price
                                            : typeof withoutFeeAndChargesPriceValue.price ===
                                                  "number" &&
                                              withoutFeeAndChargesPriceValue.price > 0
                                            ? `${getDictionaryString("agreedPrice")} ${formatPrice(
                                                  withoutFeeAndChargesPriceValue.price
                                              )}`
                                            : null}
                                    </span>

                                    {/* Fees and Charges Price */}
                                    <span className={S.extraFeesItem}>
                                        {isFeeAndChargesJSX
                                            ? feeAndChargesPriceValue.price
                                            : typeof feeAndChargesPriceValue.price === "number" &&
                                              feeAndChargesPriceValue.price > 0
                                            ? `${getDictionaryString("fees")} ${formatPrice(
                                                  feeAndChargesPriceValue.price
                                              )}`
                                            : null}
                                    </span>

                                    {/* Kvantum */}

                                    {props.priceData.orderLineKvantum !== undefined &&
                                        props.priceData.orderLineKvantum !== null && (
                                            <span className={S.extraFeesItem}>
                                                {priceStatus === "SUCCESS"
                                                    ? `${getDictionaryString("kvantum")} `
                                                    : null}
                                                {props.priceData.orderLineKvantum}
                                            </span>
                                        )}
                                </div>
                            </div>
                        )}
                        <div />
                    </div>
                    <div className={S.driverMessageButtonWrapper}>
                        {props.driverMessage && !props.farmInTimeReferenceId && (
                            <div className={S.comment}>
                                {getDictionaryString("driverMessageCartTitle")}
                                {": "}
                                {props.driverMessage}
                            </div>
                        )}
                        {!props.farmInTimeReferenceId && !props.isReceiptPage && (
                            <DA_DriverMessageMobileButton
                                productId={props.productId}
                                variantId={props.productVariantId}
                                message={props.driverMessage || ""}
                                customerNumber={customerNumber}
                                orderSecret={orderSecret}
                                onUpdateDriverMessage={updateDriverMessage}
                            />
                        )}
                    </div>
                </div>
            }
            footer={
                <div className={S.footerDrawerBtns}>
                    {props.isReceiptPage ? (
                        <DA_Button
                            title={getDictionaryString("close")}
                            isGhost={true}
                            onClick={closeOverlay}
                            className={S.button}
                        />
                    ) : (
                        <>
                            <DA_Button
                                title={getDictionaryString("remove")}
                                isGhost={true}
                                color={DA_ButtonColor.Alert}
                                onClick={props.confirmRemove}
                                className={S.button}
                            />
                            <DA_Button
                                title={getDictionaryString("close")}
                                onClick={closeOverlay}
                                className={S.button}
                            />
                        </>
                    )}
                </div>
            }
        />
    );
};
