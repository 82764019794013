import React from "react";
import { DA_Button } from "@danishagro/shared/src/components/atoms/Button/Button.component";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { DA_Container } from "@danishagro/shared/src/components/atoms/Container/Container.component";
import { DA_Text } from "@danishagro/shared/src/components/atoms/Text/Text.component";
import {
    DA_Title,
    DA_TitleTag,
} from "@danishagro/shared/src/components/atoms/Title/Title.component";
import { DA_Image } from "@danishagro/shared/src/components/atoms/Image/Image.component";
import { DA_HeroProps } from "./Hero.interface";
import S from "./Hero.module.scss";

export const DA_Hero = (props: DA_HeroProps) => {
    return (
        <div
            className={cn(
                S.wrapper,
                S.layoutCoverBlackFilter,
                S.heroImageLeft,
                props.fullWidthMobile && S.fullWidthMobile,
                props.overlayFullWidth && S.overlayFullWidth
            )}
        >
            <DA_Container noVerticalPadding>
                <div className={cn(S.inner, props.noPaddingTopBottom && S.noPaddingTopBottom)}>
                    {/** Image */}
                    {props.image ? (
                        <div className={S.imageWrapper}>
                            <DA_Image
                                src={props.image.src}
                                width={props.image.width}
                                height={props.image.height}
                                className={S.image}
                                alt={props.image.alt}
                                itemProp="image"
                            />
                        </div>
                    ) : null}

                    {/** Content */}
                    <div className={S.contentWrapper}>
                        <div className={S.content}>
                            {/** Tagline */}
                            {props.tagline ? (
                                <span className={S.tagline}>{props.tagline}</span>
                            ) : null}

                            {/** Title */}
                            {props.headline ? (
                                <DA_Title h1 tag={DA_TitleTag.H1} noMargin fullWidth>
                                    {props.headline}
                                </DA_Title>
                            ) : null}

                            {/** Text */}
                            {props.text ? <DA_Text html={props.text} noPadding /> : null}

                            {/** Buttons */}
                            <DA_Button
                                className={S.readMoreLink}
                                href={props.link?.href}
                                title={props.link?.title}
                                target={props.link?.target}
                            />
                        </div>
                    </div>
                </div>
            </DA_Container>
        </div>
    );
};
