import React, { useCallback, useEffect, useId, useMemo, useRef, useState } from "react";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { DA_Title } from "@danishagro/shared/src/components/atoms/Title/Title.component";
import { DA_InlineLink } from "@danishagro/shared/src/components/atoms/InlineLink/InlineLink.component";
import { DA_IconNames } from "@danishagro/shared/src/components/atoms/Icon/Icon.component";
import {
    _UrgentDeliverySection,
    _UrgentDeliverySectionProps,
} from "@organisms/UrgentDelivery/components/UrgentDeliverySection/UrgentDeliverySection.component";
import { getUrl } from "@danishagro/shared/src/helpers/getUrl.helper";
import { ExpressDeliveryModes } from "@danishagro/shared/src/interfaces/expressDeliveryModes.interface";
import { useCart } from "@danishagro/shared/src/contexts/cart/cart.context";
import { ModalSize, useModal } from "@danishagro/shared/src/contexts/modal.context";
import { DA_UrgentDeliveryDisabledModal } from "@organisms/UrgentDeliveryModal/UrgentDeliveryModal.component";
import { DA_UrgentDeliveryBottomSheet } from "@organisms/UrgentDeliveryBottomSheet/UrgentDeliveryBottomSheet.component";
import { useBottomSheet } from "@danishagro/shared/src/contexts/bottomSheet/bottomSheet.context";
import { useScreen } from "@danishagro/shared/src/contexts/screen.context";
import S from "./UrgentDelivery.module.scss";

export interface DA_UrgentDeliveryProps {
    defaultSelections: ExpressDeliveryModes;
    cropProtectionProducts: number;
    urgentProducts: number;
    deliveryOptions?: {
        active: boolean;
        id: string;
        name: string;
    }[];
    onChange?: (selections: ExpressDeliveryModes) => void;
    disabled?: boolean;
}

export const DA_UrgentDelivery = (props: DA_UrgentDeliveryProps) => {
    const [urgentDelivery, setUrgentDelivery] = useState(
        props.defaultSelections.isExpressDelivery || false
    );

    const { disableCropProtectionDelivery, cropProtectionDeliveryMode, updateCart } = useCart();

    const [cropProtectionDelivery, setCropProtectionDelivery] = useState(
        cropProtectionDeliveryMode
    );

    const { getDictionaryString } = useTranslations();
    const { showModal } = useModal();
    const { presentBottomSheet } = useBottomSheet();
    const { isMobile } = useScreen();

    useEffect(() => {
        // Check if either cropProtectionDelivery is "111" or "112" and one of the switches is checked
        const isAnySwitchChecked =
            cropProtectionDelivery === "111" || cropProtectionDelivery === "112";

        if (disableCropProtectionDelivery && isAnySwitchChecked) {
            showModal(<DA_UrgentDeliveryDisabledModal />, {
                size: ModalSize.MD,
                ...(isMobile && { shouldCloseOnOverlayClick: false, shouldCloseOnEsc: true }),
            });
            setCropProtectionDelivery("");
        } else return;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disableCropProtectionDelivery, cropProtectionDelivery, isMobile, showModal]);

    const urgentDeliveryTexts: _UrgentDeliverySectionProps["texts"] = useMemo(
        () => ({
            icon: DA_IconNames.Truck,
            title: getDictionaryString("urgent delivery"),
            text: getDictionaryString("urgent delivery text", {
                x:
                    `<strong>${props.urgentProducts}&nbsp;` +
                    (props.urgentProducts === 1
                        ? getDictionaryString("product").toLocaleLowerCase()
                        : getDictionaryString("products").toLocaleLowerCase()) +
                    "</strong>",
            }),
            titleColor: "blue",
        }),
        [getDictionaryString, props.urgentProducts]
    );

    const urgentDeliveryOptions = useRef([
        {
            id: useId(),
            text: getDictionaryString("urgent delivery"),
            tooltip: getDictionaryString("urgent delivery tooltip", {
                dontShowFallback: true,
            }),
        },
    ]);

    useEffect(() => {
        // Set the crop protection delivery mode from the cart data
        if (updateCart) {
            setCropProtectionDelivery(cropProtectionDeliveryMode);
        }
    }, [cropProtectionDeliveryMode, updateCart]);

    const cropProtectionTexts: _UrgentDeliverySectionProps["texts"] = useMemo(
        () => ({
            icon: DA_IconNames.PlantHand,
            title: getDictionaryString("crop protection delivery"),
            text: getDictionaryString("crop protection delivery text", {
                x:
                    `<strong>${props.cropProtectionProducts}&nbsp;` +
                    (props.cropProtectionProducts === 1
                        ? getDictionaryString("product").toLocaleLowerCase()
                        : getDictionaryString("products").toLocaleLowerCase()) +
                    "</strong>",
            }),
            titleColor: "green",
        }),
        [getDictionaryString, props.cropProtectionProducts]
    );

    const generateTooltip = (item) => {
        // Custom logic to generate tooltip based on item properties
        switch (item.id) {
            case "111":
                return getDictionaryString("crop protection delivery same day tooltip", {
                    dontShowFallback: true,
                });
            case "112":
                return getDictionaryString("crop protection delivery day to day tooltip", {
                    dontShowFallback: true,
                });
            default:
                return "";
        }
    };

    const cropProtectionOptions = useRef(
        props.deliveryOptions
            .filter((item) => item.active === true)
            .map((item) => {
                return {
                    id: item.id,
                    text: getDictionaryString(item.name),
                    tooltip: generateTooltip(item),
                    code: item.id,
                };
            })
    );

    // Utility function to handle showing modal or bottom sheet
    const showDisabledAlert = useCallback(() => {
        if (isMobile) {
            presentBottomSheet(<DA_UrgentDeliveryBottomSheet />, {
                id: "urgentDelivery-bottom-sheet",
                contentPadding: true,
                headerTitle: getDictionaryString("UrgentDelivery.maxLimitHeadline"),
                snapPoints: ({ minHeight }) => [minHeight],
            });
        } else {
            showModal(<DA_UrgentDeliveryDisabledModal />, { size: ModalSize.MD });
        }
    }, [isMobile, presentBottomSheet, showModal, getDictionaryString]);

    const handleUrgentDeliveryChange = useCallback(() => {
        if (props.disabled) {
            showDisabledAlert();
            setCropProtectionDelivery("");
        } else {
            setUrgentDelivery((x) => {
                const newValue = !x;
                props.onChange({
                    cropProtectionDeliveryMode: cropProtectionDelivery,
                    isExpressDelivery: newValue,
                });
                return newValue;
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.disabled, cropProtectionDelivery, props.onChange, showDisabledAlert]);

    const handleCropProtectionChange = useCallback(
        (id) => {
            if (props.disabled) {
                showDisabledAlert();
                setCropProtectionDelivery("");
            } else {
                const clickedCode = cropProtectionOptions.current.find((x) => x.id === id).code;
                const newCode = cropProtectionDelivery === clickedCode ? "" : clickedCode;

                setCropProtectionDelivery(newCode);

                props.onChange({
                    cropProtectionDeliveryMode: newCode,
                    isExpressDelivery: urgentDelivery,
                });
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.disabled, cropProtectionDelivery, urgentDelivery, props.onChange, showDisabledAlert]
    );

    return (
        <section>
            <header className={S.header}>
                <DA_Title h4 noMargin>
                    {getDictionaryString("urgent delivery title")}
                </DA_Title>
                {props.urgentProducts > 0 && (
                    <div className={S.appendage}>
                        <DA_InlineLink
                            title={getDictionaryString("urgent delivery deadlines link text")}
                            href={getUrl("termsAndConditionsOrderingDeadlines")}
                            icon={DA_IconNames.ExternalLink}
                            target="_blank"
                        />
                    </div>
                )}
            </header>

            {props.urgentProducts > 0 && (
                <_UrgentDeliverySection
                    texts={urgentDeliveryTexts}
                    options={urgentDeliveryOptions.current}
                    selected={urgentDelivery ? urgentDeliveryOptions.current[0].id : ""}
                    onChange={handleUrgentDeliveryChange}
                    disabled={props.disabled} // Pass disabled prop here
                />
            )}

            {props.cropProtectionProducts > 0 && (
                <_UrgentDeliverySection
                    texts={cropProtectionTexts}
                    options={cropProtectionOptions.current}
                    selected={cropProtectionDelivery || ""}
                    cropProtectionDeliveryCode={cropProtectionDelivery}
                    onChange={handleCropProtectionChange}
                    disabled={props.disabled} // Pass disabled prop here
                />
            )}
        </section>
    );
};
