import React, { useEffect, useState } from "react";
import { DA_DeliveryInfoModalData } from "@interfaces/deliveryInfoModalData.interface";
import { DA_Text } from "@danishagro/shared/src/components/atoms/Text/Text.component";
import { DA_Title } from "@danishagro/shared/src/components/atoms/Title/Title.component";
import { DA_InlineLink } from "@danishagro/shared/src/components/atoms/InlineLink/InlineLink.component";
import { getDeliveryInfoModalContent } from "@helpers/getDeliveryInfoModalContent.helper";
// Import style
import S from "./BottomSheetDeliveryInfo.module.scss";

export interface BottomSheetDeliveryInfoProps {
    specialFreightOverlayText?: string;
}

export const BottomSheetDeliveryInfo = (props: BottomSheetDeliveryInfoProps) => {
    const [modalContent, setModalContent] = useState<DA_DeliveryInfoModalData>();

    useEffect(() => {
        getDeliveryInfoModalContent().then((response) => {
            setModalContent(response);
        });
    }, []);

    return (
        <div className={S.deliveryContainer}>
            <DA_Text
                html={
                    props.specialFreightOverlayText
                        ? props.specialFreightOverlayText
                        : modalContent?.modal.description
                }
            />

            {modalContent?.modal.linkBoxes.map((item, index) => (
                <div key={index} className={S.linkbox}>
                    {item.links?.map((link, index) => (
                        <div key={index} className={S.links}>
                            {link.title && <DA_Title h3>{link.title}</DA_Title>}
                            <DA_InlineLink
                                target="_blank"
                                className={S.linkTag}
                                title={link.linkText}
                                href={link.link}
                            />
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};
