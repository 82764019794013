import React, { useEffect, useState } from "react";
import { DA_Modal } from "@danishagro/shared/src/components/molecules/Modal/Modal.component";
import { DA_DeliveryInfoModalData } from "@interfaces/deliveryInfoModalData.interface";
import { DA_Text } from "@danishagro/shared/src/components/atoms/Text/Text.component";
import { DA_Title } from "@danishagro/shared/src/components/atoms/Title/Title.component";
import { DA_InlineLink } from "@danishagro/shared/src/components/atoms/InlineLink/InlineLink.component";
import { getDeliveryInfoModalContent } from "@helpers/getDeliveryInfoModalContent.helper";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import S from "./DeliveryInfoModal.module.scss";

export interface DeliveryInfoModalContentProps {
    specialFreightOverlayText?: string;
}

export const DA_DeliveryInfoModalContent = (props: DeliveryInfoModalContentProps) => {
    const [modalContent, setModalContent] = useState<DA_DeliveryInfoModalData>();
    const { getDictionaryString } = useTranslations();

    const deliveryModalTitle = props.specialFreightOverlayText
        ? getDictionaryString("deliveryInfoOverwrite")
        : modalContent?.modal.title;

    useEffect(() => {
        getDeliveryInfoModalContent().then((response) => {
            setModalContent(response);
        });
    }, []);

    return (
        <DA_Modal title={deliveryModalTitle}>
            <DA_Text
                html={
                    props.specialFreightOverlayText
                        ? props.specialFreightOverlayText
                        : modalContent?.modal.description
                }
            />
            <div className={S.linkboxes}>
                {modalContent?.modal.linkBoxes.map((item, index) => (
                    <div key={index} className={S.linkbox}>
                        {item.links?.map((link, index) => (
                            <div key={index}>
                                {link.title && <DA_Title h3>{link.title}</DA_Title>}
                                <DA_InlineLink
                                    target="_blank"
                                    className={S.linkTag}
                                    title={link.linkText}
                                    href={link.link}
                                />
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </DA_Modal>
    );
};
