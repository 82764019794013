import { useEffect, useRef, useState } from "react";
import { DA_CartPriceData } from "@danishagro/shared/src/interfaces/price.interface";
import { DA_CartOrderLine } from "@danishagro/shared/src/interfaces/cartOrderLine.interface";
import { useAppData } from "@danishagro/shared/src/contexts/appData.context";
import { DA_CartData } from "@danishagro/shared/src/interfaces/cartData.interface";
import { useRequest } from "../../../hooks/useRequest.hook";
import { ExpressDeliveryModes } from "../../../interfaces/expressDeliveryModes.interface";

// Modified createFingerprint function
const createFingerprint = (
    customerNumber: string,
    cartId: string,
    orderLines: DA_CartOrderLine[],
    expressDeliveryModes: ExpressDeliveryModes,
    shippingDetails: DA_CartData["shippingDetails"]
) => {
    const prefix = `${customerNumber}:${cartId}`;
    const orderLinesString = orderLines.map(({ id, quantity }) => `${id}:${quantity}`).join("|");
    const expressDeliveryString = `isExpressDelivery:${expressDeliveryModes.isExpressDelivery};cropProtectionDeliveryMode:${expressDeliveryModes.cropProtectionDeliveryMode}`;
    const shippingString = `${shippingDetails?.address}:${shippingDetails?.zipCode}:${shippingDetails?.countryCode}:${shippingDetails?.id}`;
    return [prefix, orderLinesString, expressDeliveryString, shippingString].join("--");
};

type States = "LOADING" | "FAILED" | "NO_CART" | "SUCCESS";

export const useCartPrice = (
    customerNumber: string,
    cartId: string,
    orderLines: DA_CartOrderLine[],
    expressDeliveryModes: ExpressDeliveryModes,
    isUpdatingCart: boolean,
    shippingDetails?: DA_CartData["shippingDetails"]
) => {
    const [priceData, setPriceData] = useState<DA_CartPriceData>();
    const [priceStatus, setPriceState] = useState<States>("SUCCESS");
    const [latestShippingDate, setLatestShippingDate] = useState<Date>();
    const fingerPrint = useRef("");

    const controllerRef = useRef<AbortController | null>();
    const request = useRequest();
    const { showPrices } = useAppData();

    useEffect(() => {
        if (!showPrices) {
            setPriceData(undefined);
            setLatestShippingDate(undefined);
            return;
        }

        if (isUpdatingCart) {
            setPriceState("LOADING");
            setPriceData(undefined);
            setLatestShippingDate(undefined);
            return;
        }

        // Only fetch new prices if something important has changed
        const newFingerPrint = createFingerprint(
            customerNumber,
            cartId,
            orderLines,
            expressDeliveryModes,
            shippingDetails
        );

        if (fingerPrint.current !== newFingerPrint) {
            fingerPrint.current = newFingerPrint;

            if (controllerRef.current) {
                // If a request has already been sent to the API,
                // abort it before sending a new one
                controllerRef.current.abort();
            }

            if (cartId && orderLines.length) {
                controllerRef.current = new AbortController();
                setPriceState("LOADING");
                setPriceData(undefined);
                setLatestShippingDate(undefined);

                request
                    .post(
                        `prices/getbasketprice/${customerNumber}?version=4`,
                        {
                            id: cartId,
                        },
                        {
                            signal: controllerRef.current.signal,
                        }
                    )
                    .then((response) => response.json())
                    .then((data: DA_CartPriceData) => {
                        // Set price data but remove all charges of 0
                        setPriceData({
                            ...data,
                            charges: (data.charges || []).filter(
                                (fee) => fee.totalCalculated !== 0
                            ),
                            lines: (data.lines || []).map((line) => ({
                                ...line,
                                charges: (line.charges || []).filter(
                                    (fee) => fee.totalCalculated !== 0
                                ),
                            })),
                        });
                        setPriceState("SUCCESS");

                        const latestLineDate = data.lines.reduce(
                            (latest, { requestedShippingDay }) => {
                                const lineDate = new Date(requestedShippingDay);
                                return latest > lineDate ? latest : lineDate;
                            },
                            new Date()
                        );
                        setLatestShippingDate(latestLineDate);
                    })
                    .catch((err) => {
                        if (err?.name !== "AbortError") {
                            setPriceState("FAILED");
                        }
                    });
            } else if (cartId) {
                // No order lines
                setPriceState("SUCCESS");
                setPriceData({
                    charges: [],
                    currency: "",
                    lines: [],
                    priceBeforeFees: { priceWithoutVat: 0, priceWithVat: 0 },
                    shippingFee: { priceWithoutVat: 0, priceWithVat: 0 },
                    totalPrice: { priceWithoutVat: 0, priceWithVat: 0 },
                });
                setLatestShippingDate(undefined);
            } else {
                // No order secret and no order lines
                setPriceState("NO_CART");
                setPriceData(undefined);
                setLatestShippingDate(undefined);
            }
        }
    }, [
        request,
        customerNumber,
        orderLines,
        expressDeliveryModes,
        isUpdatingCart,
        showPrices,
        cartId,
        shippingDetails,
    ]);

    return { priceData, priceStatus, latestShippingDate };
};
