import React, { useCallback, useEffect, useRef } from "react";
import { DA_CartList } from "@organisms/CartList/CartList.component";
import { DA_CartTotal } from "@organisms/CartTotal/CartTotal.component";
import { useCart } from "@danishagro/shared/src/contexts/cart/cart.context";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { DA_Title } from "@danishagro/shared/src/components/atoms/Title/Title.component";
import { DA_Container } from "@danishagro/shared/src/components/atoms/Container/Container.component";
import { DA_Button } from "@danishagro/shared/src/components/atoms/Button/Button.component";
import {
    DA_EmptyState,
    DA_EmptyStateTypes,
} from "@danishagro/shared/src/components/molecules/EmptyState/EmptyState.component";
import { DA_Alert } from "@danishagro/shared/src/components/atoms/Alert/Alert.component";
import { useScreen } from "@danishagro/shared/src/contexts/screen.context";
import { DA_UrgentDelivery } from "@organisms/UrgentDelivery/UrgentDelivery.component";
import { ExpressDeliveryModes } from "@danishagro/shared/src/interfaces/expressDeliveryModes.interface";
import { useAppData } from "@danishagro/shared/src/contexts/appData.context";
import S from "./Cart.module.scss";

export const DA_Cart = () => {
    const {
        isLoadingCart,
        orderLines,
        priceData,
        cropProtection,
        priceStatus,
        expressDeliveryModes,
        updateExpressDeliveryModes,
        disableCropProtectionDelivery,
    } = useCart();
    const { showError } = useAppData();
    const { getDictionaryString } = useTranslations();
    const { scrollTo } = useScreen();
    const controllerRef = useRef<AbortController>();

    const hasCartItems = orderLines?.length > 0;
    const [hasCPDeliveryOption, hasUrgentDeliveryOption] = orderLines
        ? orderLines.reduce(
              (accumulator, line) => [
                  accumulator[0] + (line.allowCropProtectionDelivery ? 1 : 0),
                  accumulator[1] + (line.allowExpressDelivery ? 1 : 0),
              ],
              [0, 0]
          )
        : [0, 0];

    const updateExpressDeliveryMode = useCallback(
        (properties: ExpressDeliveryModes) => {
            if (controllerRef.current) {
                // If a change has already been sent to the API,
                // abort it before sending a new one
                controllerRef.current.abort();
            }

            controllerRef.current = new AbortController();
            updateExpressDeliveryModes(properties, { signal: controllerRef.current.signal }).catch(
                (err) => {
                    if (err?.name !== "AbortError") {
                        showError(err);
                    }
                }
            );
        },
        [updateExpressDeliveryModes, showError]
    );

    useEffect(() => {
        scrollTo(0);
    }, [scrollTo]);

    return (
        <DA_Container>
            <article className={S.wrapper}>
                <header className={S.header}>
                    <DA_Title h1 noMargin>
                        {getDictionaryString("cart")}
                    </DA_Title>
                </header>

                {!globalThis.isAuthenticated ? (
                    <DA_EmptyState
                        type={DA_EmptyStateTypes.Error}
                        title={getDictionaryString("You are not logged in")}
                    />
                ) : isLoadingCart ? (
                    <DA_EmptyState
                        type={DA_EmptyStateTypes.Loading}
                        title={getDictionaryString("loading cart")}
                        iconOnly
                    />
                ) : hasCartItems ? (
                    <>
                        <DA_CartList items={orderLines} />

                        {(cropProtection.allow || hasUrgentDeliveryOption > 0) && (
                            <DA_UrgentDelivery
                                cropProtectionProducts={hasCPDeliveryOption}
                                urgentProducts={hasUrgentDeliveryOption}
                                defaultSelections={expressDeliveryModes}
                                deliveryOptions={cropProtection.items}
                                onChange={updateExpressDeliveryMode}
                                disabled={disableCropProtectionDelivery}
                            />
                        )}
                    </>
                ) : (
                    <DA_EmptyState
                        fadeIn
                        type={DA_EmptyStateTypes.NoCartItems}
                        title={getDictionaryString("no products in cart")}
                    />
                )}

                {priceStatus === "FAILED" && (
                    <div className={S.error}>
                        <DA_Alert
                            content={getDictionaryString("Cart prices failed")}
                            type="error"
                            hideCloseBtn
                        />
                    </div>
                )}

                <div className={S.cartBottom}>
                    <div className={S.actions}>
                        <DA_Button
                            href="/"
                            title={getDictionaryString("continue shopping")}
                            isGhost
                            forceNativeUrl
                        />
                    </div>
                    {hasCartItems && (
                        <div className={S.cartTotal}>
                            <DA_CartTotal
                                prices={priceData}
                                loggedIn={globalThis.isAuthenticated}
                                hideButtons={!orderLines.length}
                                description={getDictionaryString("cartTotalDescription")}
                            />
                        </div>
                    )}
                </div>
            </article>
        </DA_Container>
    );
};
