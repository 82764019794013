import React from "react";
import { DA_DynamicOverlayWrapper } from "@danishagro/shared/src/hooks/dynamicOverlay/useDynamicOverlay.hook";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { useCart } from "@danishagro/shared/src/contexts/cart/cart.context";
import { DA_Button } from "@danishagro/shared/src/components/atoms/Button/Button.component";
import { useBottomSheet } from "@danishagro/shared/src/contexts/bottomSheet/bottomSheet.context";
import { formatNumber } from "@danishagro/shared/src/helpers/formatNumber.helper";
import { useAppData } from "@danishagro/shared/src/contexts/appData.context";
import S from "./UrgentDeliveryBottomSheet.module.scss";

export const DA_UrgentDeliveryBottomSheet = () => {
    const { getDictionaryString, getDictionaryItem } = useTranslations();
    const { cropProtectionDeliveryWeight } = useCart();
    const { dismissBottomSheet } = useBottomSheet();
    const { currentCulture } = useAppData();

    return (
        <DA_DynamicOverlayWrapper
            content={
                <>
                    <div className={S.title}>
                        <span className={S.urgentDeliveryLine}>{`${getDictionaryItem(
                            "UrgentDeliveryDisabledLine1"
                        )}`}</span>
                        <span className={S.highlighted}>
                            {getDictionaryString("UrgentDeliveryDisabledLimit")}
                        </span>
                    </div>

                    <div className={S.title}>
                        <span className={S.urgentDeliveryLine}>{`${getDictionaryItem(
                            "UrgentDeliveryDisabledLine2"
                        )}`}</span>
                        <span className={S.tag}>
                            {`${getDictionaryString("TotalWieght")}: `}
                            <span className={S.highlighted}>
                                {`${
                                    cropProtectionDeliveryWeight % 1 === 0
                                        ? formatNumber(
                                              cropProtectionDeliveryWeight,
                                              currentCulture,
                                              { decimals: 0 }
                                          )
                                        : formatNumber(
                                              cropProtectionDeliveryWeight,
                                              currentCulture,
                                              { decimals: 2 }
                                          )
                                } kg`}
                            </span>
                        </span>
                    </div>

                    <div className={S.helperText}>
                        {getDictionaryString("UrgentDeliveryDisabledHelperText")}
                    </div>
                </>
            }
            footer={<DA_Button title={getDictionaryString("Close")} onClick={dismissBottomSheet} />}
        />
    );
};
