import React from "react";
import { DA_Modal } from "@danishagro/shared/src/components/molecules/Modal/Modal.component";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { useCart } from "@danishagro/shared/src/contexts/cart/cart.context";
import { DA_Button } from "@danishagro/shared/src/components/atoms/Button/Button.component";
import { useModal } from "@danishagro/shared/src/contexts/modal.context";
import { formatNumber } from "@danishagro/shared/src/helpers/formatNumber.helper";
import { useAppData } from "@danishagro/shared/src/contexts/appData.context";
import S from "./UrgentDeliveryModal.module.scss";

export const DA_UrgentDeliveryDisabledModal = () => {
    const { getDictionaryString, getDictionaryItem } = useTranslations();
    const { cropProtectionDeliveryWeight } = useCart();
    const { closeModal } = useModal();
    const { currentCulture } = useAppData();

    return (
        <DA_Modal title={getDictionaryString("UrgentDeliveryDisabledHeader")}>
            <div className={S.title}>
                <span className={S.urgentDeliveryLine}>{`${getDictionaryItem(
                    "UrgentDeliveryDisabledLine1"
                )}`}</span>
                <span className={S.highlighted}>
                    {getDictionaryString("UrgentDeliveryDisabledLimit")}
                </span>
            </div>

            <div className={S.title}>
                <span className={S.urgentDeliveryLine}>{`${getDictionaryItem(
                    "UrgentDeliveryDisabledLine2"
                )}`}</span>
                <span className={S.tag}>
                    {`${getDictionaryString("TotalWieght")}: `}
                    <span className={S.highlighted}>
                        {`${
                            cropProtectionDeliveryWeight % 1 === 0
                                ? formatNumber(cropProtectionDeliveryWeight, currentCulture, {
                                      decimals: 0,
                                  })
                                : formatNumber(cropProtectionDeliveryWeight, currentCulture, {
                                      decimals: 2,
                                  })
                        } kg`}
                    </span>
                </span>
            </div>

            <div className={S.helperText}>
                {getDictionaryString("UrgentDeliveryDisabledHelperText")}
            </div>

            <div className={S.closeBtn}>
                <DA_Button title={getDictionaryString("Close")} onClick={closeModal} />
            </div>
        </DA_Modal>
    );
};
