import React, { useCallback, useState } from "react";
import { DA_BasicLink } from "@danishagro/shared/src/components/atoms/BasicLink/BasicLink.component";
import { DA_QuantityPicker } from "@molecules/QuantityPicker/QuantityPicker.component";
import { DA_Spinner } from "@danishagro/shared/src/components/atoms/Spinner/Spinner.component";
import { DA_Icon, DA_IconNames } from "@danishagro/shared/src/components/atoms/Icon/Icon.component";
import { DA_ProductTag } from "@molecules/ProductTag/ProductTag.component";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { useAppData } from "@danishagro/shared/src/contexts/appData.context";
import { useCart } from "@danishagro/shared/src/contexts/cart/cart.context";
import { DA_Text } from "@danishagro/shared/src/components/atoms/Text/Text.component";
import { useUpdateCartItem } from "@hooks/useUpdateCartItem.hook";
import { B2bImageSrc } from "@danishagro/shared/src/helpers/imageSrc.helper";
import { ImageConfig } from "@danishagro/shared/src/content/imageConfigs/imageConfig.enum";
import { formatNumber } from "@danishagro/shared/src/helpers/formatNumber.helper";
import { DA_CartItemDesktopProps } from "./CartItemDesktop.interface";
import { DA_DriverMessageButton } from "./components/DriverMessageButton/DriverMessageButton.component";
// Style
import S from "./CartItemDesktop.module.scss";

export const DA_CartItemDesktop = ({ ...props }: DA_CartItemDesktopProps) => {
    const { getDictionaryString } = useTranslations();
    const { customerNumber, showPrices, isFarmInTimeEnabled, currentCulture } = useAppData();
    const { orderSecret, priceStatus, disableCropProtectionDelivery } = useCart();

    const [currentDriverMessage, setCurrentDriverMessage] = useState<string>(props.driverMessage);

    const updateDriverMessage = (newDriverMessage: string) => {
        // Update the currentDriverMessage state with the new value
        setCurrentDriverMessage(newDriverMessage);
    };

    const {
        isWithoutFeeAndChargesJSX,
        isFeeAndChargesJSX,
        withoutFeeAndChargesPriceValue,
        feeAndChargesPriceValue,
    } = useUpdateCartItem(props);

    const formatPrice = useCallback(
        (price: number) => formatNumber(price, currentCulture, { decimals: 2 }),
        [currentCulture]
    );

    return (
        <>
            <div
                className={cn(
                    S.cartItemBox,
                    props.dimmed && S.dimmed,
                    currentDriverMessage && S.hasDriverMessage
                )}
            >
                <div className={S.mainInfo}>
                    {/** Image */}
                    <div className={S.imageWrapper}>
                        <DA_BasicLink href={props.url} className={S.imageLink}>
                            {/* TODO: Develop a new Image component for new image presets */}
                            <img
                                src={B2bImageSrc(props.imageUrl, ImageConfig.PdpThumbnail)}
                                alt={props.sizedImage.alt}
                                width={props.sizedImage.width}
                                height={props.sizedImage.height}
                                className={S.image}
                            />

                            {/* <DA_Image
                                src={props.sizedImage.url}
                                alt={props.sizedImage.alt}
                                width={props.sizedImage.width}
                                height={props.sizedImage.height}
                                className={S.image}
                            /> */}
                        </DA_BasicLink>
                    </div>

                    {/** Name */}
                    <div className={S.numberAndName}>
                        <div className={S.number}>
                            {props.productNumber}

                            {props.data.allowCropProtectionDelivery &&
                                !props.hideDeliveryOptions && (
                                    <>
                                        {disableCropProtectionDelivery && (
                                            <>
                                                <span className={S.tag}>
                                                    {`1 ${props.data.unit}: ${
                                                        props.data.itemGrossWeight % 1 === 0
                                                            ? formatNumber(
                                                                  props.data.itemGrossWeight,
                                                                  currentCulture,
                                                                  { decimals: 0 }
                                                              )
                                                            : formatNumber(
                                                                  props.data.itemGrossWeight,
                                                                  currentCulture,
                                                                  { decimals: 2 }
                                                              )
                                                    } kg`}
                                                </span>

                                                <span className={S.tag}>
                                                    {`${getDictionaryString("TotalWieght")}: ${
                                                        props.data.lineGrossWeight % 1 === 0
                                                            ? formatNumber(
                                                                  props.data.lineGrossWeight,
                                                                  currentCulture,
                                                                  { decimals: 0 }
                                                              )
                                                            : formatNumber(
                                                                  props.data.lineGrossWeight,
                                                                  currentCulture,
                                                                  { decimals: 2 }
                                                              )
                                                    } kg`}
                                                </span>
                                            </>
                                        )}
                                    </>
                                )}
                        </div>

                        <div className={S.name}>
                            <DA_BasicLink href={props.url} className={S.nameLink}>
                                {props.productName}
                            </DA_BasicLink>
                        </div>
                    </div>
                </div>

                <div className={S.actions}>
                    {/** Quantity Counter */}
                    {!props.readOnly ? (
                        <DA_QuantityPicker
                            onChange={props.updateQuantityDesktop}
                            value={props.quantity}
                            disabled={props.dimmed}
                            higherMax={!isFarmInTimeEnabled}
                        />
                    ) : (
                        <div className={S.quantity}>
                            {props.quantity} {props.unit}
                        </div>
                    )}

                    {/** Delete Button */}
                    {!props.readOnly ? (
                        props.isUpdating || props.isRemoving ? (
                            <DA_Spinner className={S.spinner} />
                        ) : (
                            <button
                                type="button"
                                className={S.removeButton}
                                onClick={props.confirmRemove}
                            >
                                <DA_Icon name={DA_IconNames.Trash} className={S.removeIcon} />
                            </button>
                        )
                    ) : null}

                    {/** Comment */}
                    {!props.hideDriverMessageButton && (
                        <div className={S.commentWrapper}>
                            <DA_DriverMessageButton
                                lineId={props.id}
                                productId={props.productId}
                                variantId={props.productVariantId}
                                message={currentDriverMessage || ""}
                                customerNumber={customerNumber}
                                orderSecret={orderSecret}
                                onUpdateDriverMessage={updateDriverMessage}
                            />
                        </div>
                    )}

                    {/** Price */}
                    {showPrices && (
                        <div className={S.total}>
                            <span className={S.totalLabel}>{getDictionaryString("total")}: </span>
                            {props.priceData.price}

                            {props.priceData.charges.length > 0 && (
                                <div className={S.charges}>
                                    {props.priceData.charges.map((fee) => (
                                        <div key={fee.code}>
                                            {fee.title}: {fee.price}
                                        </div>
                                    ))}
                                </div>
                            )}

                            <div className={cn(S.charges, S.extraFees)}>
                                {/* Agreed Price */}
                                <span className={S.extraFeesItem}>
                                    {isWithoutFeeAndChargesJSX
                                        ? withoutFeeAndChargesPriceValue.price
                                        : typeof withoutFeeAndChargesPriceValue.price ===
                                              "number" && withoutFeeAndChargesPriceValue.price > 0
                                        ? `${getDictionaryString("agreedPrice")} ${formatPrice(
                                              withoutFeeAndChargesPriceValue.price
                                          )}`
                                        : null}
                                </span>

                                {/* Fees and Charges Price */}
                                <span className={S.extraFeesItem}>
                                    {isFeeAndChargesJSX
                                        ? feeAndChargesPriceValue.price
                                        : typeof feeAndChargesPriceValue.price === "number" &&
                                          feeAndChargesPriceValue.price > 0
                                        ? `${getDictionaryString("fees")} ${formatPrice(
                                              feeAndChargesPriceValue.price
                                          )}`
                                        : null}
                                </span>

                                {/* Kvantum */}

                                {props.priceData.orderLineKvantum !== undefined &&
                                    props.priceData.orderLineKvantum !== null && (
                                        <span className={S.extraFeesItem}>
                                            {priceStatus === "SUCCESS"
                                                ? `${getDictionaryString("kvantum")} `
                                                : null}
                                            {props.priceData.orderLineKvantum}
                                        </span>
                                    )}
                            </div>
                        </div>
                    )}
                </div>

                {/** Delivery Date */}
                <div
                    className={cn(
                        S.deliveryDate,
                        props.hideDeliveryOptions && S.deliverWrapperShow
                    )}
                >
                    {showPrices && (
                        <>
                            <span
                                className={cn(
                                    S.dateLabel,
                                    props.hideDeliveryOptions && S.deliverShow
                                )}
                            >
                                {getDictionaryString("latest delivery date", {
                                    uppercaseFirst: true,
                                })}
                                :{" "}
                            </span>
                            <span>{props.priceData.shippingDate}</span>
                        </>
                    )}

                    {props.data.allowCropProtectionDelivery && !props.hideDeliveryOptions ? (
                        <DA_ProductTag icon={DA_IconNames.PlantHand} color="green" size="small">
                            {getDictionaryString("allow crop protection delivery")}
                        </DA_ProductTag>
                    ) : null}

                    {props.data.allowExpressDelivery && !props.hideDeliveryOptions ? (
                        <DA_ProductTag icon={DA_IconNames.Truck} color="blue" size="small">
                            {getDictionaryString("allow express delivery")}
                        </DA_ProductTag>
                    ) : null}
                </div>
            </div>

            {currentDriverMessage && !props.farmInTimeReferenceId && (
                <div className={S.driverMessage}>
                    <DA_Text noPadding smallText>
                        {getDictionaryString("driverMessageCartTitle")}
                        {": "}
                        {currentDriverMessage}
                    </DA_Text>
                </div>
            )}
        </>
    );
};
